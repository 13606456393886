import scanner from "./scanner.png";
import generator from "./generator.gif";
import "./App.css";
import Table from "react-bootstrap/Table";
import React, { useState } from "react";
import Web3 from "web3";
import axios from "axios";
import {
  wethAddress,
  uFactoryAddressV3,
  uFactoryAddressV2,
  uFactoryAbiV3,
  uFactoryAbiV2,
  erc20ABI,
  uniswapV2Pair,
} from "./config";
function App() {
  const [rpcUrl, setRpcUrl] = useState("wss://ethereum.publicnode.com");
  const [tokenData, setTokenData] = useState([]);
  const [isEngineRunning, setisEngineRunning] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  //wss://mainnet.infura.io/ws/v3/3a7c52d946cc4adcb4f1e8b48f5a4601
  //wss://ethereum.publicnode.com
  const handleRpcChange = (e) => {
    setRpcUrl(e.target.value);
    setisEngineRunning(false);
  };

  async function handleIt(web3, token0, token1, pair) {
    // Since we are buying this new token with WETH, we want to verify token0 & token1 address, and fetch the address of the new token?
    let path = [];
    if (token0.toLowerCase() === wethAddress.toLowerCase()) {
      path = [token0, token1];
    }

    if (token1.toLowerCase() === wethAddress.toLowerCase()) {
      path = [token1, token0];
    }

    if (path.length === 0) {
      console.log(`Pair wasn\'t created with WETH...\n`);
      return;
    }

    const uPair = new web3.eth.Contract(uniswapV2Pair, pair);
    const token = new web3.eth.Contract(erc20ABI, path[1]); // Path[1] will always be the token we are buying.

    ////console.log(`Checking liquidity...\n`);

    // Ideally you'll probably want to take a closer look at reserves, and price from the pair address
    // to determine if you want to snipe this particular token...
    const reserves = await uPair.methods.getReserves().call();
    if (reserves[0] == 0 && reserves[1] == 0) {
      //console.log(`Token has no liquidity...`);
      return;
    }
    //console.log(`Token0 WETH: ${path[0]}`);
    //console.log(`Token1: ${path[1]}`);
    //console.log(`Pair Address: ${pair}\n`);
    ////console.log(reserves);
    // let currentBlock = await web3.eth.getBlockNumber();
    // //console.log("Current block: " + currentBlock);
    const wethContract = new web3.eth.Contract(erc20ABI, path[0]);
    const totalWeth = await wethContract.methods.balanceOf(pair).call();
    ////console.log("Eth in LP: " + web3.utils.fromWei(totalWeth, "ether"));

    let checkedHoneypot = false;
    for (let index = 0; index < 5; index++) {
      if (!checkedHoneypot) {
        await delay(5);
        await axios
          .get("https://api.honeypot.is/v2/IsHoneypot", {
            headers: {},
            params: {
              address: path[1],
              pair: pair,
            },
          })
          .then((response) => {
            // //console.log(response.data);
            let totalHolders;
            let tokenName;
            let buyTax = response.data.simulationResult.buyTax;
            let sellTax = response.data.simulationResult.sellTax;
            if (
              response.data.token.address.toLowerCase() ==
              wethAddress.toLowerCase()
            ) {
              totalHolders = response.data.withToken.totalHolders;
              tokenName = response.data.withToken.name;
            } else {
              totalHolders = response.data.token.totalHolders;
              tokenName = response.data.token.name;
            }
            let isHoneyPot = response.data.honeypotResult.isHoneypot;
            let honeyPotStatus = isHoneyPot.toString();
            if (isHoneyPot) {
              honeyPotStatus =
                isHoneyPot.toString() +
                " : " +
                response.data.honeypotResult.honeypotReason +
                " : " +
                response.data.flags;
            }
            let LP =
              web3.utils.fromWei(totalWeth, "ether").toString() + " WETH";
            console.table({
              tokenName,
              LP,
              totalHolders,
              honeyPotStatus,
              buyTax,
              sellTax,
            });
            let dexscreener = "https://dexscreener.com/ethereum/" + pair;
            let tokensniffer = "https://tokensniffer.com/token/eth/" + path[1];
            const newData = {
              tokenName,
              LP,
              totalHolders,
              honeyPotStatus,
              buyTax,
              sellTax,
              dexscreener,
              tokensniffer,
            };
            setTokenData((prevData) => [...prevData, newData]);
            checkedHoneypot = true;
          })
          .catch((error) => {
            console.error(
              "Error // LP: " +
                web3.utils.fromWei(totalWeth, "ether").toString() +
                " WETH"
            );
          });
      }
    }
  }

  const main = async (web3) => {
    //console.log("started");
    const uFactoryV3 = new web3.eth.Contract(uFactoryAbiV3, uFactoryAddressV3);
    const uFactoryV2 = new web3.eth.Contract(uFactoryAbiV2, uFactoryAddressV2);
    uFactoryV3.events.allEvents({}, async (error, event) => {
      if (event.event.toString() == "PairCreated") {
        //console.log(`New pair detected v3... ${new Date()}`);
        const { token0, token1, pair } = event.returnValues;
        //console.log(event.returnValues);
        await handleIt(
          web3,
          token0.toString(),
          token1.toString(),
          pair.toString()
        );
        //console.log(`Listening for new pairs...\n`);
      } else {
        //console.log("v3/: " + event.event.toString());
      }

      // //console.log(`Listening for new pairs...\n`);
    });

    uFactoryV2.events.allEvents({}, async (error, event) => {
      try {
        if (event.event.toString() == "PairCreated") {
          //console.log(`New pair detected v2... ${new Date()}`);
          ////console.log(event);
          //console.log(event.returnValues);
          const { token0, token1, pair } = event.returnValues;

          await handleIt(
            web3,
            token0.toString(),
            token1.toString(),
            pair.toString()
          );
          //console.log(`Listening for new pairs...\n`);
        } else {
          //console.log("v2/: " + event.event.toString());
        }
      } catch (error) {
        //console.log(error);
      }
    });
  };

  const delay = (seconds) => {
    return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
  };
  let dotInterval;
  const animateDots = () => {
    let dotCount = 0;
    dotInterval = setInterval(() => {
      dotCount = (dotCount + 1) % 4; // Cycle dotCount from 0 to 3
      setLoadingMessage(`Listening for new pairs${".".repeat(dotCount)}`);
    }, 500); // Update every 500 milliseconds
  };
  const handleStart = async () => {
    try {
      //await delay(1);
      if (!rpcUrl.includes("wss://")) {
        alert("You need to add WSS RPC. You can get it from infura.io");
        return;
      }
      const web3 = new Web3(rpcUrl);
      let network_id = await web3.eth.net.getId();
      if (network_id != 1) {
        alert("Only Ethereum Mainnet is supported.");
        return;
      }
      setisEngineRunning(true);
      setLoadingMessage("Listening for new pairs");
      animateDots();
      main(web3).catch((err) => {
        setisEngineRunning(false);
        alert(err);
      });
    } catch (error) {
      setisEngineRunning(false);
      if (error.toString().includes("Error: connection not open on send")) {
        alert("Check WSS RPC is working properly.");
        return;
      }
      alert(error);
    }
  };
  return (
    <div className="App">
      <header className="App-header">
        <img
          src={scanner}
          alt="scanner"
          style={{ width: "300px", marginTop: "50px" }}
        />
        <h2>Paaca Scanner</h2>
        <a>Find Tokens as soon as liquidity is added.</a>

        <div>
          <h5>Enter WSS RPC: </h5>
          <input
            className="header-input"
            type="text"
            value={rpcUrl}
            onChange={handleRpcChange}
            placeholder="Enter RPC URL"
          />
          {isEngineRunning && (
            <>
              <br></br>
              <img
                style={{ width: "100px", height: "100px" }}
                src={generator}
                alt="generator"
              />
            </>
          )}
          <br></br>
          {!isEngineRunning && (
            <button onClick={handleStart}>Start Engine</button>
          )}

          {isEngineRunning && (
            <>
              <button
                onClick={() => {
                  setisEngineRunning(false);
                }}
              >
                Stop Engine
              </button>
              <p>{loadingMessage}</p>
              <p>As soon as pair is created in Uniswap. It will show here.</p>
              <br></br>

              <div className="table-container">
                <Table
                  responsive
                  style={{
                    width: "100%",
                    marginBottom: "400px",
                    textAlign: "-webkit-center",
                  }}
                >
                  {" "}
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Token Name</th>
                      <th>LP</th>
                      <th>Total Holders</th>
                      <th>Honeypot Status</th>
                      <th>Buy Tax</th>
                      <th>Sell Tax</th>
                      <th>Dex Scanner</th>
                      <th>Token Sniffer</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tokenData.map((data, index) => (
                      <tr key={index}>
                        <td>{index}</td>
                        <td>{data.tokenName}</td>
                        <td>{data.LP}</td>
                        <td>{data.totalHolders}</td>
                        <td>{data.honeyPotStatus}</td>
                        <td>{data.buyTax}</td>
                        <td>{data.sellTax}</td>
                        <td>
                          <a
                            href={data.dexscreener}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Check Chart
                          </a>
                        </td>
                        <td>
                          <a
                            href={data.tokensniffer}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Check Security
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </>
          )}
        </div>
      </header>
    </div>
  );
}

export default App;
